import React, { useRef } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import Select from "react-select";
import Creatable from "react-select/creatable";
import { useState } from "react";
import { universities } from "./universities";
import { useEffect } from "react";
import "react-international-phone/style.css";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

import { Worker, Viewer, SpecialZoomLevel } from "@react-pdf-viewer/core";

// "de" | "en" | "es" | "fr" | "ja" | "zh"
import tr from "react-phone-number-input/locale/tr.json";
import en from "react-phone-number-input/locale/en.json";
import de from "react-phone-number-input/locale/de.json";
import es from "react-phone-number-input/locale/es.json";
import fr from "react-phone-number-input/locale/fr.json";
import ja from "react-phone-number-input/locale/ja.json";
import zh from "react-phone-number-input/locale/zh.json";
import { PhoneInput } from "react-international-phone";
import { useIntl } from "react-intl";
import { useLang } from "../../../_metronic/i18n/Metronici18n";
import { Modal } from "react-bootstrap";
import { error } from "console";
import WidgetsPage from "../../modules/widgets/WidgetsPage";

// Define the type for the option
type OptionType = {
  label: string;
  value: string;
};

type FormData = {
  name: string;
  surname: string;
  email: string;
  phone: string;
  tc_no: string;
  university: any;
  occupation: string;
  kvkk_approval: boolean;
};

const FormCard: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    reset,
    watch,
    getFieldState,
    setError,
    resetField,
    control,
    setFocus,
  } = useForm<FormData>();

  const lang = useLang();
  const intl = useIntl();
  const [modalShow, setModalShow] = useState(false);
  const [errorPayload, setErrorPayload] = useState<any>();
  const [modalMessage, setModalMessage] = useState("");
  const [privacyModalShow, setPrivacyModalShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const intlMessages = {
    name: intl.formatMessage({ id: "FORM.NAME" }),
    surname: intl.formatMessage({ id: "FORM.SURNAME" }),
    email: intl.formatMessage({ id: "FORM.EMAIL" }),
    phone: intl.formatMessage({ id: "FORM.PHONE" }),
    tc_no: intl.formatMessage({ id: "FORM.TC_NO" }),
    university: intl.formatMessage({ id: "FORM.UNIVERSITY" }),
    occupation: intl.formatMessage({ id: "FORM.OCCUPATION" }),
    kvkk: intl.formatMessage({ id: "FORM.KVKK" }),
    modalErrorTitle: intl.formatMessage({ id: "FORM.MODAL_ERROR_TITLE" }),
    modalSuccessTitle: intl.formatMessage({ id: "FORM.MODAL_SUCCESS_TITLE" }),
    modalSuccessMessage: intl.formatMessage({
      id: "FORM.MODAL_SUCCESS_MESSAGE",
    }),
    modalClose: intl.formatMessage({ id: "FORM.MODAL_CLOSE" }),
    fieldRequired: intl.formatMessage({ id: "FORM.FIELD_REQUIRED" }),
    fieldEmail: intl.formatMessage({ id: "FORM.FIELD_EMAIL" }),
    fieldPhone: intl.formatMessage({ id: "FORM.FIELD_PHONE" }),
    fieldTC: intl.formatMessage({ id: "FORM.FIELD_TC" }),
    emailConfirmation: intl.formatMessage({ id: "FORM.EMAIL_CONFIRMATION" }),
    successMessage: intl.formatMessage({ id: "FORM.SUCCESS" }),
    errorTC: intl.formatMessage({ id: "FORM.ERROR_TC" }),
    errorUniversity: intl.formatMessage({ id: "FORM.ERROR_UNI" }),
    errorEmail: intl.formatMessage({ id: "FORM.ERROR_EMAIL" }),
    errorUni: intl.formatMessage({ id: "FORM.ERROR_UNI" }),
    errorName: intl.formatMessage({ id: "FORM.ERROR_NAME" }),
    errorSurname: intl.formatMessage({ id: "FORM.ERROR_SURNAME" }),
    errorKVKK: intl.formatMessage({ id: "FORM.ERROR_KVKK" }),
    errorTCSize: intl.formatMessage({ id: "FORM.ERROR_TC_SIZE" }),
    errorInputSize: intl.formatMessage({ id: "FORM.ERROR_INPUT_SIZE" }),
    errorTCFormat: intl.formatMessage({ id: "FORM.ERROR_INVALID_TC" }),
    privacyUrl: intl.formatMessage({ id: "URL.PRIVACY" }),
  };

  const keyToTranslate: any = (key: any) => {
    if (key === "name") {
      return intlMessages.name.toUpperCase();
    } else if (key === "surname") {
      return intlMessages.surname.toUpperCase();
    } else if (key === "email") {
      return intlMessages.email.toUpperCase();
    } else if (key === "phone") {
      return intlMessages.phone.toUpperCase();
    } else if (key === "tc_no") {
      return intlMessages.tc_no.toUpperCase();
    } else if (key === "university") {
      return intlMessages.university.toUpperCase();
    } else if (key === "occupation") {
      return intlMessages.occupation.toUpperCase();
    } else if (key === "kvkk_approval") {
      return intlMessages.kvkk.toUpperCase();
    } else {
      return key;
    }
  };

  const selectRef = useRef<any>(null);

  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  function delay(time: number) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }
  const onSubmit: SubmitHandler<FormData> = async (data) => {
    setIsLoading(true);
    await delay(1500);
    setErrorPayload(null);

    setModalMessage("");
    let endpointURL =
      "https://lb7450wdc1.execute-api.eu-central-1.amazonaws.com/api/participant";

    try {
      data["university"] = searchUniversity(data["university"]).label;

      const response = await fetch(endpointURL, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        // Set the error message from the server response
        setErrorPayload(errorData);
        setModalMessage(intlMessages.modalErrorTitle);
        setModalShow(true);
        setIsLoading(false);
        return;
      }
      setSuccess(true);
      const responseData = await response.json();
      setModalMessage(intlMessages.modalSuccessTitle);
      reset();
      if (selectRef.current) {
        selectRef.current.setValue(null, null);
      }
      setModalShow(true);
      setIsLoading(false);
    } catch (error: any) {
      console.error(error);
      setErrorPayload(error);
      setIsLoading(false);
    }
  };

  // Watch the university value once and use it in the component
  const universityValue = watch("university");

  // Define a function to set the university value
  const setUniversityValue = (value: string) => {
    setValue("university", value);
  };

  useEffect(() => {
    setUniversityValue(universityValue);
  }, [universityValue]);

  const [options, setOptions] = useState(universities);
  function searchUniversity(inputValue: string): OptionType {
    return (
      options.find((option: OptionType) => option.value === inputValue) || {
        label: inputValue,
        value: inputValue,
      }
    );
  }
  return (
    <div
      className="card  h-720px"
      style={{
        background: "#FFFFFF",

        backgroundSize: "cover",

        backgroundPosition: "center",
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)} className="form"
      style={{display: "none"}}>
        <div className="card-body ">
          <div className="d-flex flex-row ">
            <div
              className="form-group "
              style={{
                width: "47%",
              }}
            >
              <label
                style={{
                  color: "#252F3E",
                  fontWeight: "bold",
                  fontSize: "1.1rem",
                }}
                className="form-label text-gray-800 "
              >
                *{intlMessages.name}
              </label>
              <input
                style={{
                  backgroundSize: "cover",
                  backgroundColor: "transparent",
                  backgroundPosition: "center",
                  border: "1px solid #252F3E",
                }}
                {...register("name", { required: true, maxLength: 255 })}
                className="form-control text-gray-800"
              />
              <p
                style={{
                  animation: errors.name ? "pulse 1s" : "none",
                  minHeight: "1em",
                }}
                className="text-danger"
              >
                {errors.name && (
                  <p style={{ animation: "pulse 1s" }} className="text-danger">
                    {intlMessages.fieldRequired}
                  </p>
                )}
              </p>
            </div>

            <div
              className="form-group "
              style={{
                width: "47%",
                marginLeft: "5%",
              }}
            >
              <label
                style={{
                  color: "#252F3E",
                  fontWeight: "bold",
                  fontSize: "1.1rem",
                }}
                className="form-label text-gray-800 "
              >
                *{intlMessages.surname}
              </label>
              <input
                style={{
                  backgroundSize: "cover",
                  backgroundColor: "transparent",
                  backgroundPosition: "center",
                  border: "1px solid #252F3E",
                }}
                {...register("surname", { required: true, maxLength: 255 })}
                className="form-control text-gray-800"
              />
              <p
                style={{
                  animation: errors.name ? "pulse 1s" : "none",
                  minHeight: "1em",
                }}
                className="text-danger"
              >
                {errors.surname && (
                  <p style={{ animation: "pulse 1s" }} className="text-danger">
                    {intlMessages.fieldRequired}
                  </p>
                )}
              </p>
            </div>
          </div>

          <div className="form-group">
            <label
              style={{
                color: "#252F3E",
                fontWeight: "bold",
                fontSize: "1.1rem",
              }}
              className="form-label text-gray-800 "
            >
              *{intlMessages.email}
            </label>
            <input
              style={{
                backgroundSize: "cover",
                backgroundColor: "transparent",
                backgroundPosition: "center",
                border: "1px solid #252F3E",
              }}
              type="email"
              {...register("email", {
                required: true,
                maxLength: 255,
                pattern: /^\S+@\S+$/i,
              })}
              className="form-control text-gray-800"
            />
            <p
              style={{
                animation: errors.name ? "pulse 1s" : "none",
                minHeight: "1em",
              }}
              className="text-danger"
            >
              {errors.email && (
                <p style={{ animation: "pulse 1s" }} className="text-danger">
                  {intlMessages.fieldEmail}
                </p>
              )}
            </p>
          </div>
          <div className="">
            <label
              style={{
                color: "#252F3E",
                fontWeight: "bold",
                fontSize: "1.1rem",
              }}
              className="form-label text-gray-800 "
            >
              *{intlMessages.university}
            </label>

            <Creatable
              {...register("university", { required: true })}
              //change requirement message

              noOptionsMessage={() => "No university found"}
              options={options}
              isClearable={true}
              ref={selectRef}
              placeholder=""
              formatCreateLabel={(inputValue) => `${inputValue}`}
              isSearchable
              onChange={(value: any) =>
                setUniversityValue(value?.value ?? null)
              }
              className="form-group text-gray-800 transform-none"
              styles={{
                control: (styles) => {
                  return {
                    ...styles,
                    color: "#252F3E",
                    backgroundColor: "transparent",
                    border: "1px solid #252F3E",
                    fontSize: "1.1rem",
                    boxShadow: "none",

                    minHeight: "40px",
                    "&:hover": {
                      outline: "0px",
                      transition: "none",
                    },
                  };
                },
                singleValue: (styles) => {
                  return {
                    ...styles,
                    color: "#252F3E",
                    fontSize: "1.1rem",
                  };
                },
                input: (styles) => {
                  return {
                    ...styles,
                    color: "#252F3E",
                    fontSize: "1.1rem",
                  };
                },
              }}
            />

            <p
              style={{
                animation: errors.university ? "pulse 1s" : "none",
                minHeight: "1em",
              }}
              className="text-danger"
            >
              {errors.university && (
                <p style={{ animation: "pulse 1s" }} className="text-danger">
                  {intlMessages.fieldRequired}
                </p>
              )}
            </p>
          </div>

          <div className="form-group ">
            <label
              style={{
                color: "#252F3E",
                fontWeight: "bold",
                fontSize: "1.1rem",
              }}
              className="form-label text-gray-800 "
            >
              {intlMessages.occupation}
            </label>
            <input
              style={{
                backgroundSize: "cover",
                backgroundColor: "transparent",
                backgroundPosition: "center",
                border: "1px solid #252F3E",
              }}
              {...register("occupation", { required: false, maxLength: 255 })}
              className="form-control text-gray-800"
            />
            <p
              style={{
                animation: "errors.name" ? "pulse 1s" : "none",
                minHeight: "1em",
              }}
              className="text-danger"
            ></p>
          </div>

          <div className="">
            <label
              style={{
                color: "#252F3E",
                fontWeight: "bold",
                fontSize: "1.1rem",
              }}
              className="form-label text-gray-800 "
            >
              {intlMessages.phone}
            </label>
            <PhoneInput
              {...register("phone", { required: false })}
              className="text-gray-800 form-group "
              style={{
                fontSize: "1.1rem",
                border: "0.5px solid #252F3E",
                borderRadius: "0.25rem",
              }}
              inputStyle={{
                width: "100%",
                backgroundColor: "transparent",
                borderColor: "transparent",

                color: "#252F3E",
                fontSize: "1.1rem",
              }}
              defaultCountry={"tr"}
              value={getValues("phone")}
              onChange={(value?: string) => {
                setValue("phone", value || "");
              }}
            />
            <p
              style={{
                animation: errors.name ? "pulse 1s" : "none",
                minHeight: "1em",
              }}
              className="text-danger"
            >
              <p style={{ animation: "pulse 1s" }} className="text-danger"></p>
            </p>
          </div>

          <div className="form-group">
            <label
              style={{
                color: "#252F3E",
                fontWeight: "bold",
                fontSize: "1.1rem",
              }}
              className="form-label text-gray-800 "
            >
              {intlMessages.tc_no}
            </label>

            <input
              style={{
                backgroundSize: "cover",
                backgroundColor: "transparent",
                backgroundPosition: "center",
                border: "1px solid #252F3E",
                WebkitAppearance: "none",
              }}
              {...register("tc_no")}
              className="form-control text-gray-800"
              type="number"
              // disable incrementer button
            />
            <p
              style={{
                animation: errors.name ? "pulse 1s" : "none",
                minHeight: "1em",
              }}
              className="text-danger"
            >
              <p style={{ animation: "pulse 1s" }} className="text-danger"></p>
            </p>
          </div>

          <div className="form-group">
            <div className="form-check">
              <input
                {...register("kvkk_approval", { required: true })}
                type="checkbox"
                className="form-check-input text-warning "
                style={{
                  border: "1px solid #252F3E",
                }}
                id="kvkk"
              />
              <a
                href="#"
                style={{
                  color: "#252F3E",
                  fontWeight: "bold",
                  fontSize: "1.1rem",
                  textDecoration: "underline",
                }}
                className="form-check-label text-gray-800"
                onClick={() => setPrivacyModalShow(true)}
              >
                *{intlMessages.kvkk}
              </a>
            </div>
            <p
              style={{
                animation: errors.name ? "pulse 1s" : "none",
                minHeight: "1em",
              }}
              className="text-danger"
            >
              {errors.kvkk_approval && (
                <p style={{ animation: "pulse 1s" }} className="text-danger">
                  {intlMessages.fieldRequired}
                </p>
              )}
            </p>
          </div>

          <div className="d-flex form-group justify-content-end align-items-end">
            <button
              type="submit"
              disabled={isLoading}
              className="btn  align-self-end fw-bold mt-5  fw-bold d-flex flex-row justify-content-between align-items-center"
              style={{
                background: "#FF9900",
              }}
            >
              {isLoading && (
                <span
                  className="indicator-progress"
                  style={{ display: "block", marginRight: "1rem" }}
                >
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}

              <span
                style={{
                  color: "#252F3E",
                  fontWeight: "bold",
                  fontSize: "1.2rem",
                }}
              >
                {intl.formatMessage({ id: "FORM.SUBMIT" })}
              </span>
            </button>
          </div>
        </div>
      </form>
      <div
        id="messageModal"
        style={{
          position: "absolute",
          maxWidth: "75%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Modal
          backdrop="static"
          show={modalShow}
          onHide={() => {
            setModalShow(false);
          }}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title className="fs-1">
              {modalMessage === intlMessages.modalErrorTitle ? (
                <span className="text-danger fw-bold fs-1">
                  {intlMessages.modalErrorTitle}
                </span>
              ) : (
                <span className="text-success fw-bold fs-1">
                  {intlMessages.modalSuccessTitle}
                </span>
              )}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {errorPayload ? (
              <div>
                {Object.keys(errorPayload).map((key) => {
                  return (
                    <div>
                      <p
                        className="text-danger fw-bold fs-5
                    "
                      >
                        {keyToTranslate(key)}
                      </p>
                      <p className=" fs-5">
                        {Object.values(errorPayload[key]).map((value: any) => {
                          if (
                            value ===
                            "Participant with this email already exists."
                          ) {
                            value = intlMessages.errorEmail;
                          } else if (
                            value ===
                            "Participant with this TC ID already exists."
                          ) {
                            value = intlMessages.errorTC;
                          } else if (value === "Invalid TC ID.") {
                            value = intlMessages.errorTCFormat;
                          } else if (value === "University must be provided.") {
                            value = intlMessages.errorUniversity;
                          } else if (value === "Name must be provided.") {
                            value = intlMessages.errorName;
                          } else if (value === "Surname must be provided.") {
                            value = intlMessages.errorSurname;
                          } else if (
                            value ===
                            "Ensure this field has no more than 11 characters."
                          ) {
                            value = intlMessages.errorTCSize;
                          } else if (
                            value ===
                            "Ensure this field has no more than 255 characters."
                          ) {
                            value = intlMessages.errorInputSize;
                          }
                          return value;
                        })}
                      </p>
                    </div>
                  );
                })}
              </div>
            ) : (
              <span>
                <p className="text-success fs-3 fw-bold">
                  {intlMessages.modalSuccessMessage}
                </p>
                <p className="text-muted fs-3">{}</p>
              </span>
            )}
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={() => {
                setModalShow(false);
                if (success === true) {
                  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                  setSuccess(false);
                }
              }}
              className="btn btn-danger fw-bold"
            >
              {intlMessages.modalClose}
            </button>
          </Modal.Footer>
        </Modal>
      </div>
      <div
        style={{
          position: "absolute",
          maxWidth: "75%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Modal
          show={privacyModalShow}
          onHide={() => setPrivacyModalShow(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title className="fs-1">
              {intl.formatMessage({ id: "FORM.PRIVACY_TITLE" })}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              className="overflow-auto"
              style={{
                width: "100%",
                height: "600px",
              }}
            >
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                <Viewer
                  fileUrl={intlMessages.privacyUrl}
                  plugins={[defaultLayoutPluginInstance]}
                  initialPage={0}
                  httpHeaders={{ mode: "no-cors" }}
                  defaultScale={SpecialZoomLevel.PageWidth}
                />
              </Worker>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={() => setPrivacyModalShow(false)}
              className="btn btn-danger fw-bold"
            >
              {intlMessages.modalClose}
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export { FormCard };
