/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../helpers";
import { useEffect } from "react";
import { useState } from "react";
import { FormCard } from "../../../../../app/pages/dashboard/FormCard";
import { useIntl } from "react-intl";
type Props = {
  className: string;
};

let EngageWidget10: React.FC<Props> = ({ className }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const intl = useIntl();
  return (
    <div
      className="row g-5 gx-xxl-16  w-100 justify-content-between align-items-center  bg-transparent"
      style={{
        backgroundSize: "cover",

        backgroundPosition: "center",

        backgroundRepeat: "no-repeat",
        backgroundColor: "transparent",
      }}
    >
      {/* transparent */}

      <div
        className="col-xl-4 align-self-center"
        style={{ animation: "fadeInLeft 1s"}}
      >
        {/* Square Div */}
        <div>
          <div className="fs-2hx fw-bold text-gray-200 mb-10" style={{}}>
            <span className="me-2">
              {intl.formatMessage({ id: "EVENT.TITLE.START" })}
              <span
                className=" px-2 py-1 rounded-1 "
                style={{
                  color: "#FF9900",
                  marginLeft: "15px"
                }}
              >
                <br />
                {intl.formatMessage({ id: "EVENT.TITLE.MIDDLE" })}
              </span>
            </span>
            <br />
            {intl.formatMessage({ id: "EVENT.TITLE.END" })}
          </div>
          <span
            className=" text-center text-gray-200 mb-3 fs-2"
            style={{ animation: "fadeInRight 1s" }}
          >
            {" "}
            <span className="svg-icon svg-icon-info svg-icon-2x">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                version="1.1"
              >
                <title>Stockholm-icons / Home / Clock</title>
                <desc>Created with Sketch.</desc>
                <defs />
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <rect x="0" y="0" width="25" height="25" />
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z" fill="#FFFFFF" opacity="0.7"/>
                </g>
              </svg>
            </span>
            &nbsp; {intl.formatMessage({ id: "EVENT.TIME" })}
          </span>
        </div>
        <div style={{marginTop:'5px'}}>
          <span
              className=" text-center text-gray-200 mb-3 fs-2"
              style={{ animation: "fadeInRight 1s" }}
            >
              {" "}
              <span className="svg-icon svg-icon-info svg-icon-2x">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                >
                  <title>Stockholm-icons / Home / Clock</title>
                  <desc>Created with Sketch.</desc>
                  <defs />
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <rect x="0" y="0" width="25" height="25" />
                    <path d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L8 2.207l6.646 6.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293z" fill="#FFFFFF" opacity="0.7"/>
                    <path d="m8 3.293 6 6V13.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5V9.293z" fill="#FFFFFF" opacity="0.7"/>

                  </g>
                </svg>
              </span>
              &nbsp; {intl.formatMessage({ id: "EVENT.LOCATION" })}
            </span>
        </div>
      </div>
      <div className="col-xl-3" style={{ animation: "fadeInUp 1s" }}>
        <FormCard />
      </div>
    </div>
  );
};
export { EngageWidget10 };
