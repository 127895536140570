import { useEffect } from "react";
import { ILayout, useLayout } from "../../core";
import { toAbsoluteUrl } from "../../../helpers";
import { useIntl } from "react-intl";

const Footer = () => {
  const { config } = useLayout();
  const intl = useIntl();
  useEffect(() => {
    updateDOM(config);
  }, [config]);
  return (
    <footer className=" bg-white py-10 d-flex  flex-column flex-md-row w-100 justify-content-between align-items-center">
      <div className="text-dark   d-flex flex-column justify-content-between align-items-center align-self-center px-20 mb-5">
        <img
          src={toAbsoluteUrl("/media/webp/novadsa_logo_dark.svg")}
          alt="Logo"
        />
        <div className="nav nav-dark">
          <a
            href="https://www.novadsa.com/about"
            className="nav-link pr-3 pl-0"
          >
            {
              /* <FormattedMessage id="MENU.ABOUT" /> */
              intl.formatMessage({ id: "FOOTER.ABOUT.TITLE" })
            }
          </a>
          <a href="https://www.novadsa.com/products" className="nav-link px-3">
            {
              /* <FormattedMessage id="MENU.PRODUCTS" /> */
              intl.formatMessage({ id: "PRODUCTS.TITLE" })
            }
          </a>
          <a
            href="https://www.novadsa.com/contact"
            className="nav-link pl-3 pr-0"
          >
            {
              /* <FormattedMessage id="MENU.CONTACT" /> */
              intl.formatMessage({ id: "CONTACT.TITLE" })
            }
          </a>
        </div>
        <div className="social-icons mt-5">
          <a href="https://www.linkedin.com/company/novadsa" className="me-3">
            {/* bigger size */}
            <i
              className="fab fa-linkedin fs-1

            "
            ></i>
          </a>

          <a href="https://www.instagram.com/nova_dsa/" className="me-3">
            <i className="fab fa-instagram fs-1"></i>
          </a>
        </div>
      </div>
      <div className="card card-body bg-light contact-info order-1  d-flex flex-column align-items-start align-self-center" style={{
                marginRight: "10px",
                marginLeft: "10px"
              }}>
        <a
          href="https://maps.app.goo.gl/qUmAR66sVqR5cPx58"
          className="mb-2 fs-6 fw-bold text-dark"
          style={{ textDecoration: "" }}
        >
          <i className="fas fa-map-marker-alt me-2"></i>
          Tomtom Mahallesi, Nuri Ziya Sokak, No: 16, Kat: 4 Beyoğlu / İSTANBUL
        </a>
        <a href="tel:+902122453053" className="mb-2 fs-6 fw-bold">
          <i className="fas fa-phone-alt me-2"></i>+90 212 245 30 53
        </a>

        <a
          className="mb-2 fs-6 fw-bold text-dark"
          style={{ textDecoration: "underline" }}
          href="mailto:info@novadsa.com"
        >
          <i className="fas fa-envelope me-2"></i>
          info@novadsa.com
        </a>
      </div>
    </footer>
  );
};

const updateDOM = (config: ILayout) => {
  if (config.app?.footer?.fixed?.desktop) {
    document.body.classList.add("data-kt-app-footer-fixed", "false");
  }

  if (config.app?.footer?.fixed?.mobile) {
    document.body.classList.add("data-kt-app-footer-fixed-mobile", "false");
  }
};

export { Footer };
