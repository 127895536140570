import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Languages } from "../partials/layout/header-menus/Languages";
import { RightToolbar } from "../partials/layout/RightToolbar";
import { ScrollTop } from "./components/scroll-top";
import { Content } from "./components/content";
import { FooterWrapper } from "./components/footer";
import { toAbsoluteUrl } from "../helpers";
import { useState } from "react";
import {
  DrawerMessenger,
  ActivityDrawer,
  InviteUsers,
  UpgradePlan,
  ThemeModeProvider,
} from "../partials";
import { PageDataProvider } from "./core";
import { reInitMenu } from "../helpers";
import { ToolbarWrapper } from "./components/toolbar";

const MasterLayout = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const location = useLocation();
  const [isMobile, setIsMobile] = useState((window.innerWidth<720) ? true : false)
 
  //choose the screen size 
  const handleResize = () => {
    if (window.innerWidth < 720) {
        setIsMobile(true)
    } else {
        setIsMobile(false)
    }
  }

  // create an event listener
  useEffect(() => {
    window.addEventListener("resize", handleResize)
  })




  useEffect(() => {
    reInitMenu();
  }, [location.key]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <PageDataProvider>
      <ThemeModeProvider>
        <div
          className="d-flex flex-column flex-root app-root"
          id="kt_app_root"
          style={{
            backgroundSize: windowWidth > 1920 ? "100% 940px" : "2200px 1100px",
            backgroundColor: "transparent",
            backgroundOrigin: "revert",

            backgroundRepeat: "no-repeat",
            backgroundImage: `url('${toAbsoluteUrl("/media/webp/bg.png")}')`,
          }}
        >
          <div
            className="app-page flex-column flex-column-fluid w-100 justify-content-between 
          "
            id="kt_app_page"
          >
            <div className="d-flex flex-row w-100 justify-content-between align-items-center px-15 px-md-15 px-lg-15 px-xxl-15 py-5 py-md-5 py-lg-5 py-xxl-5 bg-transparent">
              {/* full width */}
              
              <div className="w-30 h-50px d-flex flex-row align-items-center h-50px"
              style={{marginLeft:"-15px"}}>
                <a href="https://aws.amazon.com/" target="_blank" style = {{
                    marginRight: isMobile ? "5%" : "10%",
                    height: isMobile ? "35%" : "75%"
                  }}>
                <img
                  src={toAbsoluteUrl("/media/webp/aws.png")}
                  alt="AWS"
                  style = {{
                    marginRight: "100%",
                    height: "100%"
                  }}
                />
                </a>
                <a href="https://novadsa.com/" target="_blank" style = {{
                    marginRight: isMobile ? "5%" : "10%",
                    height: isMobile ? "35%" : "75%"
                  }}>
                <img
                  src={toAbsoluteUrl("/media/webp/novadsa_logo_light.svg")}
                  alt="Novadsa"
                  style = {{
                    marginRight: "100%",
                    height: "100%"
                  }}
                />
                </a>
              </div>
              

              <div
                className="menu menu-rounded menu-gray-200 fw-bold  border-3px border-radius-0"
                style={{ backgroundColor: "transparent" }}
                data-kt-menu="true"
              >
                <Languages />
              </div>
            </div>
            <div
              className="app-wrapper flex-column flex-row-fluid"
              id="kt_app_wrapper"
            >
              <div
                className="app-main flex-column flex-row-fluid"
                id="kt_app_main"
              >
                <div className="d-flex flex-column flex-column-fluid">
                  <ToolbarWrapper />
                  <Content>
                    <Outlet />
                  </Content>
                </div>
                <FooterWrapper />
              </div>
            </div>
          </div>
        </div>

        {/* begin:: Drawers */}
        <ActivityDrawer />
        <RightToolbar />
        <DrawerMessenger />
        {/* end:: Drawers */}

        {/* begin:: Modals */}
        <InviteUsers />
        <UpgradePlan />
        {/* end:: Modals */}
        <ScrollTop />
      </ThemeModeProvider>
    </PageDataProvider>
  );
};

export { MasterLayout };
