/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react";
import { useIntl } from "react-intl";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import { PageTitle } from "../../../_metronic/layout/core";
import {
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget6,
  TablesWidget5,
  TablesWidget10,
  MixedWidget8,
  CardsWidget7,
  CardsWidget17,
  CardsWidget20,
  ListsWidget26,
  EngageWidget10,
  ListsWidget5,
} from "../../../_metronic/partials/widgets";
import { Languages } from "../../../_metronic/partials/layout/header-menus/Languages";
import { useLayout } from "../../../_metronic/layout/core";
import { FormCard } from "./FormCard";

const returnLinkedIn = (name:string) => {
  if(name === 'Prof. Dr. Muhammed Uludağ'){
    return "https://www.linkedin.com/in/a-muhammed-uludag-6144215a/";
  } else if(name === 'Berrin Özselçuk'){
    return "https://www.linkedin.com/in/berrin-mumcu-%C3%B6zsel%C3%A7uk-a6302829/";
  } else if(name === 'Roberta Piscitelli'){
    return "https://www.linkedin.com/in/robertapiscitelli/";
  } else if(name === 'Tuna Ersoy'){
    return "https://www.linkedin.com/in/tunaerkin/";
  } else if(name === 'Burak Özgen Güner'){
    return "https://www.linkedin.com/in/burak137/";
  } else if(name === 'Mert Tomruk')
    return "https://www.linkedin.com/in/merttomruk/";
  }



const DashboardPage: FC = () => {
  const intl = useIntl();
  const speakers = [
    {
      name: intl.formatMessage({ id: "SPEAKER.1.NAME" }),
      title: intl.formatMessage({ id: "SPEAKER.1.TITLE" }),
    },
    {
      name: intl.formatMessage({ id: "SPEAKER.2.NAME" }),
      title: intl.formatMessage({ id: "SPEAKER.2.TITLE" }),
    },
    {
      name: intl.formatMessage({ id: "SPEAKER.3.NAME" }),
      title: intl.formatMessage({ id: "SPEAKER.3.TITLE" }),
    },
    {
      name: intl.formatMessage({ id: "SPEAKER.4.NAME" }),
      title: intl.formatMessage({ id: "SPEAKER.4.TITLE" }),
    },
    {
      name: intl.formatMessage({ id: "SPEAKER.5.NAME" }),
      title: intl.formatMessage({ id: "SPEAKER.5.TITLE" }),
    },
    {
      name: intl.formatMessage({ id: "SPEAKER.6.NAME" }),
      title: intl.formatMessage({ id: "SPEAKER.6.TITLE" }),
    },
  ];

  return (
    <>
      {/* begin::Row */}

      <div className="row g-10 g-xl-10 mb-3 mb-xl-16">
        <div className="col">
          <EngageWidget10 className="h-md-10" />
        </div>
        {/* end::Col */}
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className="row gy-5 g-xl-8">
        {/* novadsa logo */}
        <div
          className="card card-xl-stretch "
          style={{
            backgroundSize: "cover",
            backgroundColor: "transparent",

            backgroundPosition: "center",

            transition:
              "width 1.5s ease-in-out , background-color 0.6s ease-in-out, background-image 0.6s ease-in-out, background-size 0.6s ease-in-out, background-position 0.6s ease-in-out, color 0.6s ease-in-out, border-color 0.6s ease-in-out, box-shadow 0.6s ease-in-out, opacity 0.6s ease-in-out, transform 0.6s ease-in-out, filter 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out, -webkit-filter 0.6s ease-in-out, -webkit-box-shadow 0.6s ease-in-out, -moz-transform 0.6s ease-in-out, -moz-filter 0.6s ease-in-out, -moz-box-shadow 0.6s ease-in-out, -ms-transform 0.6s ease-in-out, -ms-filter 0.6s ease-in-out, -ms-box-shadow 0.6s ease-in-out, -o-transform 0.6s ease-in-out, -o-filter 0.6s ease-in-out, -o-box-shadow 0.6s ease-in-out, visibility 0.6s ease-in-out, -webkit-visibility 0.6s ease-in-out, -moz-visibility 0.6s ease-in-out, -ms-visibility 0.6s ease-in-out, -o-visibility 0.6s ease-in-out, display 0.6s ease-in-out, -webkit-display 0.6s ease-in-out, -moz-display 0.6s ease-in-out, -ms-display 0.6s ease-in-out, -o-display 0.6s ease-in-out, transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out, -moz-transform 0.6s ease-in-out, -ms-transform 0.6s ease-in-out, -o-transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out, -moz-transform 0.6s ease-in-out, -ms-transform 0.6s ease-in-out, -o-transform 0.6s ease-in-out, transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in",
          }}
        >
          <div className="card-body p-0">
            <div className="d-flex flex-row flex-wrap p-8 justify-content-start justify-content-between"></div>
          </div>
        </div>
        <div className="col-xl-4">
          <ListsWidget5 className="card-xl-stretch mb-xl-8"/>
        </div>
        <div className="col-xl-8 ">
          <div className="row g-5 g-xl-5 ">
            <div className="card h-95 ">
              <div className="card-header border-0 pt-5">
                <h3 className="card-title fw-bolder fs-1">
                  {intl.formatMessage({
                    id: "ABOUT.TITLE",
                  })}
                </h3>
              </div>
              <div className="card-body border-0 pt-5 fs-3 text-muted">
                {intl.formatMessage({
                  id: "ABOUT.DESC",
                })}
              </div>
            </div>
          </div>
          <div
            className="row g-3 g-xl-4  mt-5 h-
        "
          >
            <div className="card card-xl-stretch  col-xl-6 ">
              <div className="card-header border-0 pb-5 ">
                <h3 className="card-title fw-bolder fs-1">
                  {intl.formatMessage({
                    id: "SPEAKER.TITLE",
                  })}
                </h3>
              </div>
              <div className="card-body overflow-auto vh-90">
                <div className="justify-content-between align-items-center">
                  {speakers.map((speaker, index) => {
                    return (
                      <a href={returnLinkedIn(speaker.name)} target='_blank' rel="noopener noreferrer" style={{color:'inherit'}}>
                      <div
                        className="d-flex flex-row justify-content-between  mb-5
                    bg-light rounded p-5 h-80px
                    " 
                      >
                        <div className="fw-bolder fs-5 d-flex align-self-start text-start">
                          {speaker.name}
                        </div>
                        <div className="text-muted fs-5 d-flex align-self-end text-end">
                          {speaker.title}
                        </div>
                      </div>
                      </a>
                    );
                  })}
                </div>
              </div>
            </div>
            <div
              className="col     col-xl-6
        "
            >
              <div className="card card-xl-stretch vh-80">
                <div className="card-header border-0 ">
                  <h3 className="card-title fw-bolder fs-1">
                    {intl.formatMessage({
                      id: "LOCATION.TITLE",
                    })}
                  </h3>
                  <div className="card-title fs-6 text-muted d-flex flex-column">
                    <div className="fw-bolder">
                      Galatasaray Üniversitesi, Aydın Doğan Oditoryumu <br />
                    </div>
                    <div className="">
                      Çırağan Cad. No:36 Ortaköy / Beşiktaş / İstanbul
                    </div>
                  </div>
                </div>

                <div className="card-body h-100">
                  <iframe
                    width="100%"
                    height="585px"
                    loading="lazy"
                    allowFullScreen
                    src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJkQGbULi3yhQRQCDiiZs25aM&key=AIzaSyB0L3DPmyVZxp2g0B-zkcTjASqRZXcAfWw"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col g-3 g-xl-4  card g-xl-4   mb-10 ">
            <div className="card-header border-0 pt-5">
              <h3 className="card-title fw-bolder fs-1">
                {intl.formatMessage({
                  id: "SPONSOR.TITLE",
                })}
              </h3>
            </div>
            <div className="card-body mb-5 mb-xl-4 d-flex flex-row flex-wrap justify-content-between align-items-center">
              <div
                className=" h-200px col-2 "
                style={{
                  backgroundSize: "contain",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundImage: `url(${toAbsoluteUrl(
                    "/media/webp/aws-dark.svg"
                  )})`,
                }}
              ></div>
              <div
                className="h-200px  col-2"
                style={{
                  backgroundSize: "contain",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundImage: `url(${toAbsoluteUrl(
                    "/media/webp/gsu.png"
                  )})`,
                }}
              ></div>
              <div
                className=" h-100px  col-4 "
                style={{
                  marginLeft: "-5%",
                  backgroundSize: "contain",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundImage: `url(${toAbsoluteUrl(
                    "/media/webp/novadsa_logo_dark.svg"
                  )})`,
                }}
              ></div>
              <div
                className=" h-200px  col-2 "
                style={{
                  marginLeft: "-5%",
                  backgroundSize: "contain",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundImage: `url(${toAbsoluteUrl(
                    "/media/webp/gsuiad.png"
                  )})`,
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>

      {/* end::Row */}
    </>
  );
};

const DashboardWrapper: FC = () => {
  const intl = useIntl();
  const { config } = useLayout();

  return (
    <>
      <DashboardPage />
    </>
  );
};

export { DashboardWrapper };
