/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { KTSVG } from "../../../helpers";
import { Dropdown1 } from "../../content/dropdown/Dropdown1";
import { useIntl } from "react-intl";

type Props = {
  className: string;
};

const ListsWidget5: React.FC<Props> = ({ className }) => {
  const intl = useIntl();
  const schedule = [
    {
      time: intl.formatMessage({ id: "SCHEDULE.1.TIME" }),
      title: intl.formatMessage({ id: "SCHEDULE.1.TITLE" }),
      // speaker: intl.formatMessage({ id: "SCHEDULE.1.SPEAKER" }),
      // affiliation: intl.formatMessage({ id: "SCHEDULE.1.AFFILIATION" }),
    },
    {
      time: intl.formatMessage({ id: "SCHEDULE.2.TIME" }),
      title: intl.formatMessage({ id: "SCHEDULE.2.TITLE" }),
      speaker: intl.formatMessage({ id: "SCHEDULE.2.SPEAKER" }),
      affiliation: intl.formatMessage({ id: "SCHEDULE.2.AFFILIATION" }),
    },
    {
      time: intl.formatMessage({ id: "SCHEDULE.3.TIME" }),
      title: intl.formatMessage({ id: "SCHEDULE.3.TITLE" }),
      speaker: intl.formatMessage({ id: "SCHEDULE.3.SPEAKER" }),
      affiliation: intl.formatMessage({ id: "SCHEDULE.3.AFFILIATION" }),
    },
    {
      time: intl.formatMessage({ id: "SCHEDULE.4.TIME" }),
      title: intl.formatMessage({ id: "SCHEDULE.4.TITLE" }),
      speaker: intl.formatMessage({ id: "SCHEDULE.4.SPEAKER" }),
      affiliation: intl.formatMessage({ id: "SCHEDULE.4.AFFILIATION" }),
    },
    {
      time: intl.formatMessage({ id: "SCHEDULE.5.TIME" }),
      title: intl.formatMessage({ id: "SCHEDULE.5.TITLE" }),
      speaker: intl.formatMessage({ id: "SCHEDULE.5.SPEAKER" }),
      affiliation: intl.formatMessage({ id: "SCHEDULE.5.AFFILIATION" }),
    },
    {
      time: intl.formatMessage({ id: "SCHEDULE.6.TIME" }),
      title: intl.formatMessage({ id: "SCHEDULE.6.TITLE" }),
      speaker: intl.formatMessage({ id: "SCHEDULE.6.SPEAKER" }),
      affiliation: intl.formatMessage({ id: "SCHEDULE.6.AFFILIATION" }),
    },
    {
      time: intl.formatMessage({ id: "SCHEDULE.7.TIME" }),
      title: intl.formatMessage({ id: "SCHEDULE.7.TITLE" }),
      speaker: intl.formatMessage({ id: "SCHEDULE.7.SPEAKER" }),
      affiliation: intl.formatMessage({ id: "SCHEDULE.7.AFFILIATION" }),
    },
    {
      time: intl.formatMessage({ id: "SCHEDULE.8.TIME" }),
      title: intl.formatMessage({ id: "SCHEDULE.8.TITLE" }),
      speaker: intl.formatMessage({ id: "SCHEDULE.8.SPEAKER" }),
      affiliation: intl.formatMessage({ id: "SCHEDULE.8.AFFILIATION" }),
    },
  ];

  const colorList = [
    "text-primary",
    "text-warning",
    "text-success",
    "text-danger",
    "text-info",
    "text-dark",
  ];

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className="card-header align-items-center border-0 mt-4">
        <h3 className="card-title align-items-start flex-column">
          <span className=" text-dark fs-1 fw-bold">
            {intl.formatMessage({ id: "SCHEDULE.TITLE" })}
          </span>
        </h3>
        <div className="card-toolbar">{/* end::Menu */}</div>
      </div>
      {/* end::Header */}

      <div className="card-body pt-5 overflow-auto h-800px">
        {/* begin::Timeline */}

        {schedule.map((item, index) => (
          <div key={index} className="timeline-label">
            <div className="timeline-item">
              <div className="timeline-label fw-bold text-gray-700 fs-5">
                {item.time}
              </div>
              <div className="timeline-badge">
                <i
                  className="fa fa-genderless  fs-1"
                  style={{ color: "#FF9900" }}
                ></i>
              </div>
              <div
                className="card-rounded bg-light  border-gray-300 p-4 w-100 mb-20"
                style={{
                  borderLeft: "2px solid ",
                  marginTop: "-1.3rem",
                }}
              >
                <div className="timeline-content " style={{ color: "#252F3E" }}>
                  <span className="fw-bold ps-3 fs-4">{item.title}</span>
                </div>
                {item.speaker && (
                  <span className="text-gray-800 ps-3 fs-4">
                    <em>{item.speaker}</em>
                  </span>
                )}
                {item.affiliation && (
                  <span className="text-gray-800 ps-3 fs-5 font-italic">
                    <em>{item.affiliation}</em>
                  </span>
                )}
              </div>
            </div>
          </div>
        ))}

        {/* end::Timeline */}
      </div>
      {/* end: Card Body  */}
    </div>
  );
};

export { ListsWidget5 };
